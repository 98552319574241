<template>
  <div class="w-100" id="table">
  <div class="title-container align-items-center fs-4 mb-2 rounded-0 text-white ">
    <h3 class="title fs-4 m-0 ps-3 py-3">Select your indicators</h3>
  </div>
  <div class="menu ">
    <ul class="first-label text-white mb-3">
      <li class="first-li" v-for="(lvl1item, lvl1index) in menuItems" :key="lvl1index">
        <div v-if="lvl1item.id.split('?')[0] !== 'overallcountryrisk'" class="label-container d-flex align-items-center">
          <label class="first" :for="lvl1item.id"><div class="align-span d-flex align-items-center"><span>{{ lvl1item.label }}</span><span class="material-icons">arrow_drop_down</span></div></label>
          <input class="custom-checkbox ms-auto" type="checkbox" :id="lvl1item.id + '-end'" v-model="lvl1item.checked">
        </div>
        <input class="d-none" type="checkbox" :id="lvl1item.id">
          <ul class="lvl2menu pb-0 mb-0  pe-0" v-if="lvl1item.submenu && lvl1item.submenu.length > 0">
            <li v-for="(lvl2item, lvl2index) in lvl1item.submenu" :key="lvl2index">
            <div class="label-container d-flex align-items-center">
              <label class="second" :for="lvl2item.id"><div class="align-span d-flex align-items-center"><span>{{ lvl2item.label }}</span><span class="material-icons">arrow_drop_down</span></div></label>
              <input class="custom-checkbox ms-auto" type="checkbox" :id="lvl2item.id + '-end'" v-model="lvl2item.checked">
            </div>
              <input class="d-none" type="checkbox" :id="lvl2item.id">
              <ul class="lvl3menu pb-0 mb-0  pe-0" v-if="lvl2item.submenu && lvl2item.submenu.length > 0">
                <li v-for="(lvl3item, lvl3index) in lvl2item.submenu" :key="lvl3index">
                  <div class="label-container d-flex align-items-center">
                  <label class="second" :for="lvl3item.id"><div class="align-span d-flex align-items-center"><span>{{ lvl3item.label }}</span><span class="material-icons">arrow_drop_down</span></div></label>
                  <input class="custom-checkbox ms-auto" type="checkbox" :id="lvl3item.id + '-end'" v-model="lvl3item.checked">
                </div>
                  <input class="d-none" type="checkbox" :id="lvl3item.id">
                  <ul class="lvl4menu pb-0 mb-0  pe-0" v-if="lvl3item.submenu && lvl3item.submenu.length > 0">
                    <li v-for="(lvl4item, lvl4index) in lvl3item.submenu" :key="lvl4index">
                      <div class="label-container d-flex align-items-center">
                        <label class="second" :for="lvl4item.id">{{ lvl4item.label }}</label>
                        <input class="custom-checkbox ms-auto" type="checkbox" :id="lvl4item.id + '-end'" v-model="lvl4item.checked">
                      </div>
                      <input class="d-none" type="checkbox" :id="lvl4item.id">
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
/* eslint-disable */
import api from '@/services/api'
import html2pdf from 'html2pdf.js';

export default {
  props: {
    IndicatorName: {
      type: Object,
      required: true,
    },
    // menuItems: Array
  },
  data() {
    return {
      inputStrings: [],
      menuItems: [],
      RenameItem: {specificNames: {}},
      csvPathFile: 'app.json',
      selectedC: '',
      country: {},
      region: '',
      regions_data: {},
      regionISO3: ''
    };
  },
  async mounted() {
    this.getDataFromCsv();
    this.getSsindLab();
  },
  watch: {
    menuItems: {
      handler(newMenuItems) {
        this.setupWatchers(newMenuItems);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setupWatchers(menuItems) {
      menuItems.forEach(lvl1item => {
        this.watchLvlItem(lvl1item);
        lvl1item.submenu && lvl1item.submenu.forEach(lvl2item => {
          this.watchLvlItem(lvl2item);
          lvl2item.submenu && lvl2item.submenu.forEach(lvl3item => {
            this.watchLvlItem(lvl3item);
            lvl3item.submenu && lvl3item.submenu.forEach(lvl4item => {
              this.watchLvlItem(lvl4item);
            });
          });
        });
      });
    },
    watchLvlItem(item) {
      this.$watch(() => item.checked, (newChecked) => {
        this.updateCheckedStatus(item, newChecked);
        this.emitLvl4ItemChanged(item); // Emit whenever any lvl4item changes
      }, { deep: true });
    },
    updateCheckedStatus(item, checked) {
      if (item.submenu && item.submenu.length > 0) {
        item.submenu.forEach(subItem => {
          subItem.checked = checked;
          this.updateCheckedStatus(subItem, checked); // Recursively update all levels
        });
      }
    },
    emitLvl4ItemChanged(lvl4item) {
      this.$emit('lvl4item-changed', { id: lvl4item.id, checked: lvl4item.checked });
    },
    buildRenameItem(obj, path) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const item = obj[key];
          const newPath = path ? `${path}/${key}` : key;
          if (item && item.code && item.code.indexOf('/') === -1) {
            this.RenameItem[item.code] = { label: item.description, rank : item.rank, Rvalue : item.Rvalue };
          } else {
            this.RenameItem.specificNames[item.code] = { label: item.description, rank : item.rank, Rvalue : item.Rvalue };
          }
          if (typeof item === 'object' && !Array.isArray(item)) {
            this.buildRenameItem(item, newPath);
          }
        }
      }
    },
    getDataFromCsv() {
      for (const key in this.IndicatorName) {
        if (this.IndicatorName.hasOwnProperty(key)) {
          const item = this.IndicatorName[key];
          this.inputStrings.push(item.code);
        }
      }
      this.buildRenameItem(this.IndicatorName, '');
    },
    async getSsindLab() {
      try {
          const response = await api.get('/ssind');
          const data = response.data;
          this.inputStrings.forEach(inputString => {
              this.generateMenu(inputString);
          });

          this.RenameInput(this.menuItems);
          // console.log("this.menuItems: ",this.menuItems)
      } catch (error) {
          console.error('Erreur lors de la récupération des Indicateurs :', error);
      }
    },
    generateMenu(inputString) {
      const levels = inputString.split('/');
      let currentLevel = this.menuItems;

      levels.forEach((level) => {
        let existingItem = currentLevel.find(item => item.label === level);

        if (!existingItem) {
          const menuItem = {
            id: `${level}?${Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)}`,
            label: level,
            submenu: [],
            checked: false,
          };
          currentLevel.push(menuItem);
          currentLevel = menuItem.submenu;
        } else {
          currentLevel = existingItem.submenu;
        }
      });
    },
    RenameInput(tab) {
      this.recursiveRename(tab);
  },
  recursiveRename(submenu, parentLabel = null) {
    submenu.forEach((item) => {
      const itemLabel = item.label;
      const combinedLabel = parentLabel ? parentLabel + "/" + itemLabel : itemLabel;

      if (this.RenameItem.specificNames[combinedLabel] && this.RenameItem.specificNames[combinedLabel].label) {
        item.label = this.RenameItem.specificNames[combinedLabel].label;
      } else if (parentLabel && this.RenameItem.specificNames[parentLabel] && this.RenameItem.specificNames[parentLabel].label) {
        item.label = this.RenameItem.specificNames[parentLabel].label;
      } else if (this.RenameItem[itemLabel] && this.RenameItem[itemLabel].label) {
        item.label = this.RenameItem[itemLabel].label;
      }

      if (item.submenu && item.submenu.length > 0) {
        this.recursiveRename(item.submenu, combinedLabel);
      }
    });
  },
  },
};
</script>

  
