<template>

  <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              {{ header }}
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              {{ message }}
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              {{ footer }}
              <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      header: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        required: false,
      },
      footer: {
        type: String,
        required: false,
      }
    },
    data() {
      return {
        hasError: true,
      };
    },
    watch: {
      error(newValue) {
        this.hasErrorLocal = newValue;
      },
    },
    methods: {
      clearError() {
        this.hasError = false;
        this.$emit('ERROR', this.hasError);
      },
    },
    beforeUnmount() {
        this.hasError = true;
    }
  };
  </script>