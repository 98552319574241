<template>
    <!-- <h1 class="fs-3 m-0 ps-5 py-2">{{ this.title }}</h1> -->
    <div class="hello helloCountryMap mt-1 w-100 bg-white" id="chartdivMap">
    </div>
  </template>
  
  <script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  /* eslint-disable */
  import { countryToAlpha3 } from "country-to-iso";

  export default {
  data() {
    return {
      selectedCountry: { id: '', value: 0 },
    }
  },
  props: {
      map: {
        type: Object,
        required: true,
      },
      country: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        required: true,
      }
    },
  methods: {
    getColor(value) {
      /*const ranges = [1, 1.5, 2, 2.5, 3, 3.5, 4, 5];
      const colors = ['#005c00', '#029602', '#3be33b', '#ffd700', '#f59218', '#ff0000', '#cc0000'];*/
      const ranges = [1, 2.001, 3, 3.5, 4, 5];
      const colors = ['#029602', '#78be21ff', '#f1c232', '#eb4b52', '#cc0000'];
      let category = '';
      if (value >= 5) {
        category = colors[4];
      } else {
        category = '#808080'
        for (let i = 1; i < ranges.length; i++) {
          if (value >= ranges[i - 1] && value < ranges[i]) {
            category = colors[i - 1];
            break;
          }
        }
      }
      return category;
    },
    modifyCountryToAlpha3(countryIdOrName) {
            const originalCountryToAlpha3 = countryToAlpha3;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo') | (countryIdOrName === 'XK')) {
                return 'KSV';
            } else {
                return originalCountryToAlpha3(countryIdOrName);
            }
        }, 
    GetCountry() {
      this.countryIn = this.modifyCountryToAlpha3(this.$route.params.element);
      if (this.map.hasOwnProperty(this.countryIn)) {
        this.selectedCountry.id = this.map[this.countryIn].name;
        this.selectedCountry.value = this.map[this.countryIn].risk;
      }
    },
    renderChart() {
      am4core.useTheme(am4themes_animated);
      let chart = am4core.create("chartdivMap", am4maps.MapChart);

      chart.geodata = am4geodata_worldLow;

      chart.projection = new am4maps.projections.Miller();
      chart.homeGeoPoint = {
        latitude: 30, // Keep it centered vertically
        longitude: 50 // Shift to the left horizontally, adjust this value as needed
      };
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      polygonSeries.useGeodata = true;
      const mapData = Object.values(this.map).map(item => ({
        id: item.country_id,
        value: item.value,
        value_str: item.value > 0 ? Math.round(item.value * 100) / 100 : '',
        fill: am4core.color(this.getColor(item.value)),
      }));
      polygonSeries.data = mapData;
      chart.deltaLongitude = -10;
      var legend = new am4maps.Legend();
      legend.parent = chart.chartContainer;
      legend.background.fill = am4core.color("#000");
      legend.background.fillOpacity = 0.05;
      legend.width = 275;
      legend.align = "right";
      legend.padding(10, 15, chart.chartContainer.maxHeight, 15);
      legend.data = [{
        "name": "Low (1 to 2)",
        "fill":"#029602"
      }, {
        "name": "Moderately Low (2.01 to 2.99)",
        "fill": "#78be21ff"
      }, {
        "name": "Moderately High (3 to 3.49)",
        "fill": "#f1c232"
      }, {
        "name": "High (3.5 to 3.99)",
        "fill": "#eb4b52"
      }, {
        "name": "Very High (4 to 5)",
        "fill": "#cc0000"
      },
      {
        "name": "no/partial assessment \ndue to lack of data",
        "fill": "#808080"
      }];
      legend.itemContainers.template.clickable = false;
      legend.itemContainers.template.focusable = false;

      var legendTitle = legend.createChild(am4core.Label);
      legendTitle.text = "Risk level :";

      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.applyOnClones = true;
      polygonTemplate.togglable = true;
      polygonTemplate.tooltipText = "{name} : {value_str}";
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.strokeOpacity = 0.5;
      polygonTemplate.fill = 'rgb(128, 128, 128)';
      // polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      // polygonTemplate.events.on("hit", this.handleHit);
      polygonTemplate.propertyFields.fill = "fill";

      polygonSeries.exclude = ["AQ"];
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix =  'my_own_score'
      chart.exporting.menu.align = "left";
      chart.exporting.menu.verticalAlign = "top";

      chart.exporting.menu.items = [
        {
            label: '<i class="fas fa-download"></i>',  // Using a download icon from FontAwesome
            menu: [
                { type: "png", label: "PNG" }
            ]
        }
    ];

    }
  },
  mounted() {
    this.GetCountry()
    this.renderChart()
  },
  watch: {
    map: {
      handler(newValue, oldValue) {
        this.renderChart()
      },
      deep: true
    }
  }
}
  
  </script>
