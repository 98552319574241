<template>
    <div class="loading_screenshot">
        <div class="capture_loading w-100 h-100 d-flex align-items-center justify-content-center">
        <p class="screen_progress">Screenshot in progress</p>
        </div>
    </div>
    <div class="content-container pe-3">
        <div class="title-container w-100 fixed-top z-100 text-white">
        <h2 class="title fs-2 d-flex align-items-center justify-content-center p-3 mb-0">
            <strong>{{ selectedC }}</strong>
            <img v-if="selectedC" :src="drapeauUrl" alt="Drapeau du pays" class="drapeau" style="width: 50px; height: 50px;">
        </h2>
    </div>
    <div :style="{ marginTop: selectedC ? '80px' : '0' }">
        <div class="d-flex align-items-center justify-content-between mx-5" v-if="country.length > 0">
  <div class="dropdown-content">
    <!-- Your MenuDropDown component -->
    <MenuDropDown :data="country" :title="title" :queryTitle="queryTitle" :path="path" :push="true" :margin="true" />
  </div>
  <div class="button-container d-flex justify-content-end">
    <button @click="exportToPDF" class="download_countryprofile d-flex align-items-center justify-content-center border border-0 text-white" :class="{ hovered: isHovered }">
          <span class="fs-6">Export to PDF</span>
    </button>
  </div>
</div>
    <div class="country-risk">
        <div class="center-verticaly d-flex flex-column align-items-center justify-content-center mb-1" v-if="Object.keys(data_country).length > 0">
                <ProgressBar :data_country="data_country" />
        </div>
    </div>
    <div v-if="this.ispartialscore" class="partialscore_text text-center mb-1">
        No sufficient data available for complete assessment.
   </div>
    <div v-if="this.iscritical === 1" class="embargo_text text-center my-3">
        The Global Country Risk of this country has been penalized because of the embargo measures.
   </div>

   <div v-html="sentence" class="autosentence_text center-verticaly d-flex flex-column align-items-center justify-content-center mb-1 m-4">

   </div>
    <div class="wrapper ms-2 my-3 d-flex justify-content-center flex-wrap flex-xl-nowrap col gap-3">
        <div class="conteneur col-12 col-xl-6" v-if="Object.keys(data_country).length > 0">
            <SolidGauge :data_country="data_country"/>
        </div>
    <div class="progress-bar col-12 col-xl-6 mt-3 mt-xl-0" v-if="Object.keys(all_data_country).length > 0">
        <MapWorld :all_data_country="all_data_country"/>
    </div>
    </div>
    <div class="indicator d-block w-100 ms-2" v-if="Object.keys(data_indicator).length > 0 && this.$route.params.element">
        <Indicator
            :selectedC="selectedC"
            :data="data"
            :data_indicator="data_indicator"
            :data_indicator_world="data_indicator_world"
            :data_indicator_region="data_indicator_region"
        />
    </div>
    <div class="indicator d-block w-100 ms-2" v-if="Object.keys(data_indicator).length > 0 && this.$route.params.element">
        <IndicatorPDF
            :selectedC="selectedC"
            :data="data"
            :data_indicator="data_indicator"
            :data_indicator_world="data_indicator_world"
            :data_indicator_region="data_indicator_region"
        />
    </div>
    </div>

</div>
</template>

<script>
/* eslint-disable */
import ProgressBar from '../components/CountryProfile/CountryRisk.vue'
import MapWorld from '../components/CountryProfile/CountryMap'
import SolidGauge from '../components/CountryProfile/CountryBar'
import MenuDropDown from '../components/UI/MenuDropDown'
import Indicator from '../components/CountryProfile/CountryIndicator'
import IndicatorPDF from '../components/CountryProfile/CountryIndicatorPDF.vue'
import api from '@/services/api'
import { countryToAlpha2, countryToAlpha3 } from "country-to-iso";
import IndicatorName from "./app.json"
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { hideElements } from '../services/utilities'; // Path to utilities.js
import { unhideElements } from '../services/utilities'; // Path to utilities.js

export default {
    components: {
        ProgressBar,
        MapWorld,
        SolidGauge,
        MenuDropDown,
        Indicator,
        IndicatorPDF
    },
    data () {
        return {
            data_country: {},
            all_data_country: {},
            country: [],
            data_indicator: [],
            data_indicator_world: [],
            data_indicator_region: [],
            regions_data: [],
            map: {},
            selectedC: this.$route.params.element,
            drapeauUrl: '',
            title: 'Select a country',
            queryTitle: 'Search Country',
            path: 'country',
            data: IndicatorName,
            iscritical: '',
            sentence: '',
            ispartialscore:'',
        }
    },
    methods: {
        async exportToPDF() {
            try {
                /*const expandSubRows = async (row) => {
                const checkbox = row.querySelector('input[type="checkbox"]');
                    if (checkbox) {
                        checkbox.click(); // Click on the checkbox to expand sub-rows
                        await new Promise(resolve => setTimeout(resolve, 500)); // Add delay if needed

                        const subRows = row.querySelectorAll('.lvl2menu > li');
                        for (let j = 0; j < subRows.length; j++) {
                        await expandSubRows(subRows[j]); // Recursively expand sub-rows
                        }
                    }
                };

                const menuRows = document.querySelectorAll('.menu > ul > li');
                
                // Loop through each menu row to expand sub-rows
                for (let i = 0; i < menuRows.length; i++) {
                await expandSubRows(menuRows[i]);
                }*/

                const loading = document.querySelector(".loading_screenshot");
                loading.classList.add('capture-mode_loading');

                const menuRows = document.querySelectorAll('.menu > ul > li');
                for (let i = 0; i < menuRows.length; i++) {
                    const checkbox = menuRows[i].querySelector('input[type="checkbox"]');
                    checkbox.click();
                    await new Promise(resolve => setTimeout(resolve, 500));
                }

                hideElements('.dropdown-container','.button-container');
                const container = document.querySelector('.content-container');
                const titleContainer = document.querySelector('.title-container');
                titleContainer.style.position = 'static';

                if (container) {
                    const canvas = await html2canvas(container);

                    const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, mm, A4 size

                    const image = canvas.toDataURL('image/png');
                    const imgWidth = 190; // A4 size: 210mm - adjusted for margins
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    const maxContentHeight = 250; // Maximum content height per page (adjust as needed)
                    const maxImageHeight = maxContentHeight; // Considering no content spacing

                    let imgWidthAdjusted = imgWidth;
                    let imgHeightAdjusted = imgHeight;

                    if (imgHeightAdjusted > maxImageHeight) {
                        const ratio = maxImageHeight / imgHeightAdjusted;
                        imgHeightAdjusted = maxImageHeight;
                        imgWidthAdjusted *= ratio;
                    }

                    const marginLeft = 10; // Left margin in mm
                    let marginTop = 10; // Top margin in mm

                    pdf.addImage(image, 'PNG', marginLeft, marginTop, imgWidthAdjusted, imgHeightAdjusted);
                    
                    /*// Convert the PDF to a blob
                    const pdfBlob = pdf.output('blob');

                    // Create a URL for the blob
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    const a = document.createElement('a');
                    a.href = pdfUrl;
                    a.download = 'countryprofile_' + this.selectedC + '.pdf';

                    // Simulate a click on the anchor to trigger the download
                    a.click();

                    // Cleanup: remove the temporary anchor and revoke the URL object
                    URL.revokeObjectURL(pdfUrl);
                    a.remove();*/

                    pdf.save('countryprofile_' + this.selectedC + '.pdf');

                    // Revert the display property to its original state after capture
                    unhideElements('.dropdown-container','.button-container');
                    titleContainer.style.position = 'fixed';
                    const menuRows = document.querySelectorAll('.menu > ul > li');
                    for (let i = 0; i < menuRows.length; i++) {
                        const checkbox = menuRows[i].querySelector('input[type="checkbox"]');
                        checkbox.click();
                        await new Promise(resolve => setTimeout(resolve, 500));
                    }
                    loading.classList.remove('capture-mode_loading');
                }
            } catch (error) {
                console.error('Error exporting to PNG:', error);
            }
        },
        modifyCountryToAlpha2(countryIdOrName) {
            const originalCountryToAlpha2 = countryToAlpha2;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo')) {
                return 'XK';
            } else {
                return originalCountryToAlpha2(countryIdOrName);
            }
        },
        modifyCountryToAlpha3(countryIdOrName) {
            const originalCountryToAlpha3 = countryToAlpha3;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo')) {
                return 'KSV';
            } else {
                return originalCountryToAlpha3(countryIdOrName);
            }
        },      
        async getAll() {
            try {
                const response = await api.get(`/countryprofile/${this.modifyCountryToAlpha3(this.selectedC)}?start_date=2024`)
                const data = response.data
                this.iscritical = response.data.iscritical
                this.sentence = response.data.auto_sentence
                const pillar1 = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'pilier1');
                const pillar2 = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'pilier2');
                const pillar3 = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'pilier3');
                const pillar4 = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'pilier4');
                const risk = data.data.find(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC) && item.symbol == 'overallcountryrisk');

                this.ispartialscore = !(pillar1.myvalue!== null && pillar2.myvalue!== null && pillar3.myvalue!== null && pillar4.myvalue!== null);

                this.data_country = {
                    name: this.modifyCountryToAlpha3(pillar1.country_id),
                    pillar1: Math.round(pillar1.myvalue * 100) / 100,
                    pillar2: pillar2.myvalue,
                    pillar3: pillar3.myvalue,
                    pillar4: pillar4.myvalue,
                    risk: risk.myvalue
                };
                data.data.forEach(item => {
                    this.all_data_country[item.country_id] = {
                        pillar1: null,
                        pillar2: null,
                        pillar3: null,
                        pillar4: null,
                        risk: null,
                        name: null,
                    };
                })
                data.data.forEach(item => {
                this.all_data_country[item.country_id].name = this.modifyCountryToAlpha2(item.country_id)
                if (item.symbol == 'pilier1') {
                    this.all_data_country[item.country_id].pillar1 = item.myvalue;
                }
                if (item.symbol == 'pilier2') {
                    this.all_data_country[item.country_id].pillar2 = item.myvalue;
                }
                if (item.symbol == 'pilier3') {
                    this.all_data_country[item.country_id].pillar3 = item.myvalue;
                }
                if (item.symbol == 'pilier4') {
                    this.all_data_country[item.country_id].pillar4 = item.myvalue;
                }
                if (item.symbol == 'overallcountryrisk') {
                    this.all_data_country[item.country_id].risk = item.myvalue;
                }
            })
            const filteredData = data.data.filter(item => item.country_id == this.modifyCountryToAlpha3(this.selectedC));
            filteredData.forEach(item => {
                const newItem = {
                    name: item.country_id,
                    symbol: item.symbol,
                    myvalue: item.myvalue,
                    rank: item.rank
                };
                this.data_indicator.push(newItem);
            });
            const filteredDataWorld = data.data.filter(item => item.country_id == 'WLD');
            filteredDataWorld.forEach(item => {
                const newWorldItem = {
                    name: item.country_id,
                    symbol: item.symbol,
                    value: Math.round(item.myvalue * 100) / 100,
                }
                this.data_indicator_world.push(newWorldItem);
            })
            const filteredDataRegion = data.data.filter(item =>  item.country_id == 'EEU' || 
                                                                item.country_id == 'CAM' ||
                                                                item.country_id == 'CEA' ||
                                                                item.country_id == 'CEAF'||
                                                                item.country_id == 'EAA'||
                                                                item.country_id == 'EAF'||
                                                                item.country_id == 'EEU'||
                                                                item.country_id == 'NAF'||
                                                                item.country_id == 'NEU'||
                                                                item.country_id == 'NME'||
                                                                item.country_id == 'NOAM'||
                                                                item.country_id == 'OCE'||
                                                                item.country_id == 'SAF'||
                                                                item.country_id == 'SAM'||
                                                                item.country_id == 'SEA'||
                                                                item.country_id == 'SEU'||
                                                                item.country_id == 'SOA'||
                                                                item.country_id == 'WAF'||
                                                                item.country_id == 'WEU'
                                                            );
            filteredDataRegion.forEach(item => {

                const newRegionItem = {
                    name: item.country_id,
                    symbol: item.symbol,
                    value: Math.round(item.myvalue * 100) / 100,
                }
                this.data_indicator_region.push(newRegionItem);
            })
            } catch(error) {
                console.log('error', error)
            }
        },
        async getRegions() {
        try {
            const response = await api.get('/regions');
            const data = response.data;
            data.data.forEach(obj => {
                    this.regions_data.push(obj.id)
            })
        } catch (error) {
            console.error('Erreur lors de la récupération des regions_data :', error);
        }
        },
        async fetchDrapeauUrl(pays) {
            try {
                // const response = await fetch(`https://restcountries.com/v3/alpha/${this.modifyCountryToAlpha2(pays)}`);
                // const data = await response.json();
                // this.drapeauUrl = data[0].flags[1];
                this.drapeauUrl = `https://flagsapi.com/${this.modifyCountryToAlpha2(pays)}/shiny/64.png`
            } catch (error) {
                console.error('Erreur lors de la récupération du drapeau :', error);
                this.drapeauUrl = 'URL_DU_DRAPEAU_PAR_DÉFAUT';
            }
        },
        async getCountries() {
            try {
                const response = await api.get('/countries?sorttype=country_name&cnttype=REG');
                const data = response.data;
                this.country = data.data.map(item => [item.country_name]);

            } catch (error) {
                console.error('Erreur lors de la récupération des pays :', error);
            }
        },
        async selectedCountry() {
            this.selectedC = this.$route.params.element;
            if (this.selectedC != undefined) {
                await this.fetchDrapeauUrl(this.selectedC);
            }
        },
    },
    mounted() {
        this.getRegions();
        this.getAll();
        this.getCountries();
        this.selectedCountry();
    },
}
</script>