<template>
    <div>
        <div class="content-container ">
            <div class="title-container text-white p-2">
                <p class="text-center fs-1 m-1">
                    Country Risk Platform<br/>
                </p>
                <p class="text-center fs-4 m-1">
                    operated by TAC ECONOMICS for Veolia purposes<br/>
                </p>
            </div>
        </div>
        <div class="justify-content-center m-2 intro-container">
            <p class="intro text-center fs-4 m-1">
                <br/><br/>This platform assesses the Economic & Business, Geopolitical & Political, Social & Societal and Environmental situation of 184 countries.
            </p>
            <p class="intro text-center fs-4 m-1">
            It is built on <b>external data</b> only and provides an <b>external perception of the risks</b>.
            </p>
            <p class="intro text-center fs-4 m-1">
            Country risks can impact the multifaceted performance or the operations of any company.
            </p>
        </div>

        <div class="search-bar-container container d-flex justify-content-center align-items-center mx-auto col">
            <ul class="resultats mb-0 bg-white border border-1 border-white col-12 col-lg-10 opacity-1">
                <li class="searchBox">
                    <input
                    class="border border-0"
                    v-model="searchTerm"
                    placeholder="Search for a country or an indicator"
                    @click.stop
                    @keyup.enter="performSearch"
                    />
                </li>
                <li
                    v-for="(item, index) in filteredCountries"
                    :key="index"
                    :class="{ active: selectedIndex === index }"
                    @click="new_selected(item)"
                    > {{ item[0] }}
                </li>
            </ul>
        </div>

        <div v-if="showDisclaimer" class="disclaimer-popup d-flex justify-content-center align-items-center align-self-center fixed-top w-100 h-100">
            <div class="modal d-flex align-items-center justify-content-center w-100 h-100">
                <div class="header text-white text-center flex-column align-items-center justify-content-center fs-3 p-4 pt-3">
                    <h2>Disclaimer</h2>
                    <div class="content bg-white text-black w-100 h-100 d-flex flex-column align-items-center justify-content-center text-center fs-4 m-0 px-3 py-4">
                        <p>The Country Risk Platform offers a perception of country risk as perceived by major international institutions and/or globally recognized research centers.<br><br>
                        It expresses long term & systemic gross risk. This platform is based on open source data and is updated every 6 months. Therefore, it does not capture current or one-off events.<br><br>
                        The ratings provided by the tool do not supersede the operational experience of our on-site collaborators and/or the opinions and analyses of experts from our functional departments (e.g., Security Department, Compliance, etc.).<br><br>
                        We, therefore, encourage you to contact the appropriate department in case of suspected risk.</p>
                        <button class="button-disclaimer text-white border border-0 fs-5 mt-3 px-4 py-1" @click="acceptDisclaimer">Understood</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="Object.keys(map).length > 0">
            <CountryRiskMap :map="map" :title="title" :subtitle="subtitle" />
        </div>
    </div>
</template>

<script> 
/* eslint-disable */
import CountryRiskMap from '../components/HomePage/CountryMap.vue'
import api from '@/services/api'
import IndicatorName from "./app.json"
import { useStore } from 'vuex';
import { countryToAlpha2 } from "country-to-iso";

export default {
    computed: {
        showDisclaimer() {
            return this.$store.state.showDisclaimer;
        },
    },
    components: {
        CountryRiskMap
    },
    data () {
        return {
            map: {
            },
            selectedC: '',
            drapeauUrl: '',
            title: 'Global Country Risk Assessment',
            subtitle: 'Consolidation of 4 pillars: Economic & Business, Political & Geopolitical, Social & Societal and Environmental <strong>(data updated every 6 months)</strong>',
            country: [],
            data: IndicatorName,
            searchTerm: '',
            filteredCountries: this.country,
            selectedIndex: -1,
        }
    },
    methods: {
        modifyCountryToAlpha2(countryIdOrName) {
            const originalCountryToAlpha2 = countryToAlpha2;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo')) {
                return 'XK';
            } else {
                return originalCountryToAlpha2(countryIdOrName);
            }
        },
        acceptDisclaimer() {
            this.$store.commit('acceptDisclaimer');
        },
        new_selected(array) {
            if (array[1] == 'country') {
                this.$router.push(`/country/${array[0]}`)
            } else {
                this.$router.push(`/indicator/${array[2]}`)
            }
        },
        performSearch() {
            const searchTermLower = this.searchTerm.toLowerCase();
            const foundItem = this.filteredCountries.find(item => item[0].toLowerCase() === searchTermLower);
            if (foundItem) {
                this.new_selected(foundItem);
            }
        },
        handleKeyPress(event) {
            if (event.key === 'ArrowUp') {
                this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
            } else if (event.key === 'ArrowDown') {
                if (Array.isArray(this.filteredCountries) && this.filteredCountries.length > 0) {
                this.selectedIndex = Math.min(this.selectedIndex + 1, this.filteredCountries.length - 1);
                }
            } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
                this.new_selected(this.filteredCountries[this.selectedIndex]);
            } else if (event.key === 'Escape') {
                this.filteredCountries = [];
                this.selectedIndex = -1;
            }
        },
        handleClickOutside(event) {
            const menuContainer = document.querySelector('.search-bar-container');

            // Check if the menuContainer exists and the click is outside of it
            if (menuContainer && !menuContainer.contains(event.target)) {
                this.filteredCountries = [];
                this.selectedIndex = -1;
            }
        },
        async getOverallCountryRisk() {
            try {
                const reponse = await api.get('/data/ssind/overallcountryrisk?start_date=2024-01-01&end_date=2024-12-31');
                const data = reponse.data;
                data.data.forEach(obj => {
                    const Objname = obj.country_id;
                    this.map[Objname] = {
                        name: this.modifyCountryToAlpha2(obj.country_id),
                        risk: obj.myvalue,
                    };
                    console.log(Objname)
                    console.log(this.map[Objname])
                });
            } catch(error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        },
        async getCountries() {
            try {
                const response = await api.get('/countries');
                const data = response.data;
                this.country = data.data.map(item => [item.country_name, 'country']);
                this.getDataFromJson()
            } catch (error) {
                console.error('Erreur lors de la récupération des pays :', error);
            }
        },
        async selectedCountry() {
            this.selectedC = this.$route.params.element;
        },
        getDataFromJson() {
            for (const key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                    const item = this.data[key];
                    const all_code = item.code.split('/')
                    const raw_code = all_code[all_code.length - 1];
                    const itemArray = [item.description, 'indicator', raw_code];
                    this.country.push(itemArray);
                }
            }
        },
        filterCountries() {
          if (this.searchTerm.trim() === '') {
            this.filteredCountries = [];
          } else {
            this.filteredCountries = this.country.filter((item) =>
            item[0].toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
      },
    },
    watch: {
      searchTerm() {
        this.filterCountries();
      },
    },
    created() {
    // Add event listener for clicks on the document body
        document.body.addEventListener('click', this.handleClickOutside);
    },
    async mounted() {
        await this.getOverallCountryRisk();
        await this.getCountries();
        this.selectedCountry();
        document.addEventListener('keydown', this.handleKeyPress);
    },
    destroyed() {
        document.removeEventListener('keydown', this.handleKeyPress);
        document.body.removeEventListener('click', this.handleClickOutside);
    },
}
</script>