<template>
    <div class="loading_screenshot">
        <div class="capture_loading w-100 h-100 d-flex align-items-center justify-content-center">
            <p class="screen_progress">Computing score</p>
        </div>
    </div>
    <div>
        <div class="content-container pe-3">
            <div class="title-container w-100 fixed-top z-100 text-white">
                <p class="text-center fs-1 m-1">
                    Make your own risk Assessment<br/>
                </p>
            </div>
        </div>

        <div v-if="showAlert" @close="showModal = false">
            <AlertModal :header="alertheader" :message="alertmsg" :footer="alertfooter" @close="handleAlertClose" />
        </div>

        <div class="justify-content-center m-2 intro-container">
            <p class="intro text-center fs-4 m-1">
                <br>Make your own risk assessment by selecting manually individual indicators, and applying different weight to them <br>in order to create a fully personalized score which respond to <b>your own needs</b><br>.
            </p>
        </div>
        
        <div class="button-container d-flex justify-content-end">
            <button @click="export_score" 
                    class="download_myos d-flex align-items-center justify-content-center border border-0 text-white" 
                    :class="{ hovered: isHovered, 'bg-secondary': !is_score_calculated, 'text-muted': !is_score_calculated }" 
                    :disabled="!is_score_calculated"
                    >
                <span class="fs-6">Export to CSV</span>
            </button>
        </div>

        <div v-if="Object.keys(data_map).length > 0">
            <MyScoreMap :map="data_map"/>
        </div>
        <div class="d-flex justify-content-center" style="margin-left: 2.5%;margin-right: 2.5%;margin-top: 50px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <IndicatorList @lvl4item-changed="updateIndicators" 
                            :IndicatorName="IndicatorName"
                        />
                    </div>
                    <div class="col-12 col-md-1 d-flex justify-content-center">
                        <img :src="currentSrc" alt="Add indicator" style="width: 4rem; height: 4rem;margin-top: 150px;"/>
                    </div>
                    <div class="col-12 col-md-5">
                        <IndicatorUseList
                            :listIndicators="indicators_raw"
                        />
                    </div>
                    <div class="col-12 col-md-1">
                        <div class="button-container">
                            <button @click="calculScore" class="download_myos m-1 d-flex align-items-center justify-content-center border border-0 text-white" :class="{ hovered: isHovered }">
                                <span class="fs-6">Calcul score</span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>   
    </div>
</template>

<script> 
/* eslint-disable */
import MyScoreMap from '../components/MyScore/MyScoreMap.vue'
import api from '@/services/api'
import IndicatorName from "./app.json"
import { useStore } from 'vuex';
import { countryToAlpha2 } from "country-to-iso";
import IndicatorList from '../components/MyScore/indicators_list_tab';
import IndicatorUseList from '../components/MyScore/indicators_use_tab';
import fastForwardStatic from '@/assets/fast-forward.png';
import fastForwardGif from '@/assets/fast-forward.gif';
import AlertModal from '../components/UI/AlertModal.vue'

export default {
    computed: {
        showDisclaimer() {
            return this.$store.state.showDisclaimer;
        },
    },
    components: {
        MyScoreMap,
        AlertModal,
        IndicatorList,
        IndicatorUseList,
    },
    data () {
        return {
            isAnimating: false, // Track whether the GIF is animating
            staticImage: fastForwardStatic,
            animatedGif: fastForwardGif,
            currentSrc: fastForwardStatic, // Current source of the image
            data_raw: [],
            indicators_raw: [],
            data_map: [],
            IndicatorName: IndicatorName,
            isModalErrorVisible: false,
            alertheader: '',
            alertmsg: '',
            alertfooter:'',
            showAlert: false,
            is_score_calculated: false
        }
    },
    methods: {
        export_score() {
            const usedIndicators = this.indicators_raw.filter(indicator => indicator.use === 1).map(record => record.symbol);
            usedIndicators.push('overallcountryrisk');

            let all_data = [
                ...this.data_raw,  // Spread the existing data_raw records
                ...this.data_map.map(record => ({  // Spread data_map after mapping it to the required format
                    country_id: record.country_id_iso3,
                    symbol: record.symbol,
                    value: record.value
                }))
            ];           
            const data_filtered = all_data.filter(record => usedIndicators.includes(record.symbol));
            
            // Extract all unique country IDs and symbols from all_data
            const uniqueCountryIds = [...new Set(data_filtered.map(item => item.country_id))];
            let uniqueSymbols = [...new Set(data_filtered.map(item => item.symbol))];

            // Ensure 'overallcountryrisk' comes first
            uniqueSymbols = ['overallcountryrisk', ...uniqueSymbols.filter(symbol => symbol !== 'overallcountryrisk')];

            // Start creating the CSV content
            let csvContent = "codes," + uniqueSymbols.join(",") + "\n";  // Header row

            // Create the weights row
            const weightsRow = ['weights', '100'];  // First two cells
            uniqueSymbols.slice(1).forEach(symbol => {
                const indicator = this.indicators_raw.find(indicator => indicator.symbol === symbol);
                weightsRow.push(indicator ? indicator.w : '');  // Get weight or leave empty
            });
            csvContent += weightsRow.join(",") + "\n";  // Add weights row

            // Create the names row
            const namesRow = ['indicator name', 'Overall Country Risk'];  // First two cells
            uniqueSymbols.slice(1).forEach(symbol => {
                const indicator = this.indicators_raw.find(indicator => indicator.symbol === symbol);
                namesRow.push(indicator ? indicator.name : '');  // Get name or leave empty
            });
            csvContent += namesRow.join(",") + "\n";  // Add names row

            // For each country_id, build the data rows
            uniqueCountryIds.forEach(country_id => {
                let row = [country_id];  // Start the row with the country_id

                // For each symbol, find the corresponding value in all_data
                uniqueSymbols.forEach(symbol => {
                    const found = data_filtered.find(item => item.country_id === country_id && item.symbol === symbol);
                    row.push(found ? found.value : '');  // Add the value if found, otherwise leave it empty
                });

                csvContent += row.join(",") + "\n";  // Join the row values with commas and add to CSV
            });

            // Trigger CSV download in the browser
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "my_own_score.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        handleAlertClose() {
            this.showAlert = false;
        },
        updateIndicators(menuItems) {
            // Update indicators_raw based on the checked status of menuItems
            for (const indicator of this.indicators_raw) {
                if (indicator.symbol == menuItems.id.split('?')[0]) {
                    indicator.use = menuItems.checked ? 1 : 0;
                    break;
                }
            }
        },
        modifyCountryToAlpha2(countryIdOrName) {
            const originalCountryToAlpha2 = countryToAlpha2;
            if ((countryIdOrName === 'KSV') | (countryIdOrName === 'Kosovo')) {
                return 'XK';
            } else {
                return originalCountryToAlpha2(countryIdOrName);
            }
        },
        acceptDisclaimer() {
            this.$store.commit('acceptDisclaimer');
        },
        new_selected(array) {
            if (array[1] == 'country') {
                this.$router.push(`/country/${array[0]}`)
            } else {
                this.$router.push(`/indicator/${array[2]}`)
            }
        },
        performSearch() {
            const searchTermLower = this.searchTerm.toLowerCase();
            const foundItem = this.filteredCountries.find(item => item[0].toLowerCase() === searchTermLower);
            if (foundItem) {
                this.new_selected(foundItem);
            }
        },
        // handleKeyPress(event) {
        //     if (event.key === 'ArrowUp') {
        //         this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
        //     } else if (event.key === 'ArrowDown') {
        //         if (Array.isArray(this.filteredCountries) && this.filteredCountries.length > 0) {
        //         this.selectedIndex = Math.min(this.selectedIndex + 1, this.filteredCountries.length - 1);
        //         }
        //     } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
        //         this.new_selected(this.filteredCountries[this.selectedIndex]);
        //     } else if (event.key === 'Escape') {
        //         this.filteredCountries = [];
        //         this.selectedIndex = -1;
        //     }
        // },
        // handleClickOutside(event) {
        //     const menuContainer = document.querySelector('.search-bar-container');

        //     // Check if the menuContainer exists and the click is outside of it
        //     if (menuContainer && !menuContainer.contains(event.target)) {
        //         this.filteredCountries = [];
        //         this.selectedIndex = -1;
        //     }
        // },
        async getdata() {
            try {
                const reponse = await api.get('/makeyourownrisk');
                const data = reponse.data;

                this.data_raw = data.data.map(item => ({'country_id':item.country_id, 'symbol':item.symbol, 'value':item.value}));
                this.indicators_raw = data.indicators.map(item => ({'symbol':item.symbol, 'w':item.w, 'use':item.use, 'name':item.name, 'locked':false}));

                const uniqueCountryIds = [...new Set(data.data.map(item => item.country_id))];
       
                const result = uniqueCountryIds.map(country_id => {
                    return {
                        country_id: this.modifyCountryToAlpha2(country_id),
                        country_id_iso3: country_id,
                        value: null,
                    };
                });
                this.data_map = result;
              } catch(error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        },

        async calculScore() {
            const loading = document.querySelector(".loading_screenshot");
            loading.classList.add('capture-mode_loading');

            try {
                const uniqueCountryIds = [...new Set(this.data_raw.map(item => item.country_id))];
                const usedIndicators = this.indicators_raw.filter(indicator => indicator.use === 1);
                
                const totalWeight = usedIndicators.reduce((sum, indicator) => sum + indicator.w, 0);
                
                const margin_error = 1e-5;
                if (totalWeight < 100 - margin_error || totalWeight > 100 + margin_error) {
                    this.showAlert = true;
                    this.alertheader = 'Warning : weights error';
                    this.alertmsg = `Sum of weights must be equal to 100 (currently ${totalWeight}).\nUse the Auto-complete weights button to help you.`;
                    return;
                    // alert(`Sum of weights must be equal to 100 (currently ${totalWeight}).\nUse the Auto-complete weights button to help you.`); // Display a popup message if sum is not 100
                }

                const result = await this.computeScore(uniqueCountryIds, usedIndicators);

                // Update data_map with new result
                this.data_map = result;
                this.is_score_calculated = true 
            } catch (error) {
                console.error('Error during calculation:', error);
                // Handle error if necessary
            } finally {
                // Hide loading screen after computations complete (whether success or error)
                loading.classList.remove('capture-mode_loading'); 
            }
        },
        async computeScore(uniqueCountryIds, usedIndicators) {
            // Simulate heavy computation with a delay
            await this.simulateDelay(1000); 

            // Perform computation
            const result = uniqueCountryIds.map(country_id => {
                let tmpresult = 0;

                // Filter out indicators that have no corresponding data for this country
                const totalAvailableWeight = usedIndicators.reduce((sum, indicator) => {
                    const correspondingData = this.data_raw.find(d => d.country_id === country_id && d.symbol === indicator.symbol);
                    
                    // Only add the weight if the corresponding data is found and its value is not null or undefined
                    if (correspondingData && correspondingData.value != null) {
                        return sum + indicator.w;
                    }
                    return sum;
                }, 0);

                usedIndicators.forEach(indicator => {
                    const correspondingData = this.data_raw.find(d => d.country_id === country_id && d.symbol === indicator.symbol);
                    
                    // Only compute if the corresponding data exists and its value is not null or undefined
                    if (correspondingData && correspondingData.value != null) {
                        const adjustedWeight = indicator.w / totalAvailableWeight; // Adjust the weight
                        tmpresult += correspondingData.value * adjustedWeight;
                    }
                });

                return {
                    country_id: this.modifyCountryToAlpha2(country_id),
                    country_id_iso3: country_id,
                    value: tmpresult ? tmpresult : null,
                    symbol : 'overallcountryrisk',
                };
            });

            return result;
        },
        async simulateDelay(ms) {
        // Utility function to simulate delay (replace with actual computation logic)
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async getCountries() {
            try {
                const response = await api.get('/countries');
                const data = response.data;
                this.country = data.data.map(item => [item.country_name, 'country']);
                this.getDataFromJson()
            } catch (error) {
                console.error('Erreur lors de la récupération des pays :', error);
            }
        },
        async selectedCountry() {
            this.selectedC = this.$route.params.element;
        },
        getDataFromJson() {
            for (const key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                    const item = this.data[key];
                    const all_code = item.code.split('/')
                    const raw_code = all_code[all_code.length - 1];
                    const itemArray = [item.description, 'indicator', raw_code];
                    this.country.push(itemArray);
                }
            }
        },
        filterCountries() {
          if (this.searchTerm.trim() === '') {
            this.filteredCountries = [];
          } else {
            this.filteredCountries = this.country.filter((item) =>
            item[0].toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
      },
      animateGif() {
      setInterval(() => {
        this.isAnimating = true;
        this.currentSrc = this.animatedGif; // Switch to the animated GIF
        setTimeout(() => {
          this.isAnimating = false;
          this.currentSrc = this.staticImage; // Switch back to the static image
        }, 1100); // Play GIF animation for 1 second (or the duration of your GIF)
      }, 10000); // Repeat every 10 seconds
    },
    },
    watch: {
      searchTerm() {
        this.filterCountries();
      },
    },
    created() {
    // Add event listener for clicks on the document body
        document.body.addEventListener('click', this.handleClickOutside);
    },
    async mounted() {
        await this.getdata();
        await this.getCountries();
        this.selectedCountry();
        this.currentSrc = this.staticImage; // Start with the static image
        this.animateGif();
        // document.addEventListener('keydown', this.handleKeyPress);
    },
    destroyed() {
        // document.removeEventListener('keydown', this.handleKeyPress);
        // document.body.removeEventListener('click', this.handleClickOutside);
    },
}
</script>