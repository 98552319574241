<template>
  <div class="w-100" id="table">
    <div class="title-container d-flex align-items-center fs-4 mb-2 rounded-0 text-white">
      <!-- Title -->
      <h3 class="title fs-4 m-0 ps-3 ps-md-5 py-2">Selected indicators</h3>
      <!-- Spacer -->
      <!-- Button added to the very right side -->
      <!-- <button class="btn btn-primary me-2" @click="completeW"  @mouseover="showDescription($event, 'This is a description of the picture')">Auto-complete <br>weigths</button> -->
      <button class="btn btn-primary me-2" @click="completeW" >Auto-complete <br>weigths</button>
      <!-- <div class="btn btn-primary me-2 button-container"> -->
        <!-- <button class="info-button btn btn-primary me-2 button-container" >
          Auto-complete <br>weigths
          <span class="extra-info">
            <span class="circle">
              <span class="interrogation-mark" @click="completeW"  @mouseover="showDescription($event, 'Equally adjust all weights to match 100%. If indicators are locked, their weights wont change and the remaining ')" @mouseleave="hideDescription">?</span>
            </span>
          </span>
        </button> -->
      <!-- </div> -->
    </div>
  <div v-if="description" class="description-box" :style="descriptionStyle">
      {{ description }}
    </div>
  <div class="menu_myos">
    <ul class="myos_first_label text-white mb-3">
          <li class="first-li d-flex align-items-center justify-content-between position-relative" 
              v-for="indicator in sortedIndicators" 
              :key="indicator.symbol" 
              :class="{ 'locked-row': indicator.locked }"
          >
            <div class="d-flex align-items-center">
              <span class="cross unlocked_element ms-2 me-2" @click="removeIndicator(indicator)" v-if="!indicator.locked">&#x2716;</span>
              <span class="cross locked_element ms-2 me-2" @click="removeIndicator(indicator)" v-else>&#x2716;</span>
              <label class="second unlocked_element" v-if="!indicator.locked"> {{ indicator.name }} </label>
              <label class="second locked_element" v-else> {{ indicator.name }} </label>
            </div>
            <div class="d-flex align-items-center">
              <input class="small-input unlocked_element me-1" type="number" v-model="indicator.w" v-if="!indicator.locked"/>
              <input class="small-input locked_element me-1" type="number" v-model="indicator.w" v-else/>
              <span class="percent-sign unlocked_element" v-if="!indicator.locked">%</span>
              <span class="percent-sign locked_element" v-else>%</span>
            </div>
            <span class="lock-icon position-absolute" @click="toggleLock(indicator)" v-if="!indicator.locked">
              <img src="@/assets/unlocked_icon.png" alt="Unlocked Icon" style="width: 1.5rem; height: 1.5rem;"/>
             </span>
             <span class="lock-icon position-absolute" @click="toggleLock(indicator)" v-else>
              <img src="@/assets/locked_icon.png" alt="Locked Icon" style="width: 1.5rem; height: 1.5rem;"/>
            </span>
          </li>
        </ul>
  </div>
</div>
</template>

<script>
export default {
  props: {
    listIndicators: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sortedIndicators: [],
      filteredIndicators: [],
      description: '',
      descriptionStyle: {},
    };
  },
  methods: {
    showDescription(event, text) {
      // Ensure the event target exists
      if (!event || !event.target) return;
      
        const rect = event.target.getBoundingClientRect();

        this.description = text;
        this.descriptionStyle = {
        top: `${rect.top + window.scrollY}px`, // Position it at the top of the element
        left: `${rect.left + window.scrollX -50}px`, // Position it at the left of the element
        width: `150px`, // Match the width of the element
        height: `120px`, // Match the height of the element
        padding: '20px', // Add padding for better readability
        // maxWidth: `${rect.width * 1.5}px`, // Optional: limit max width to 1.5x the element's width
        position: 'absolute', // Ensure it is positioned relative to the element

        backgroundColor: '#021559', // Add a background to make text readable
        color: 'white', // Text color
        zIndex: 1000, // Ensure it appears above other elements
        borderRadius: '5px', // Optional: make the description box rounded
        pointerEvents: 'none', // Prevent interaction with the description itself
        whiteSpace: 'normal', // Allow text to wrap inside the box
        textAlign: 'center', // Center the text horizontally
      };
    },
    hideDescription() {
      this.description = '';
      this.descriptionStyle = {};
    },
    completeW() {
      let lockedSum = 0;
      let lockedCount = 0;

      this.sortedIndicators.forEach(indicator => {
        if (indicator.locked) {
          lockedSum += parseFloat(indicator.w); // Convert to float if necessary
          lockedCount++;
        }
      });

      // Calculate the remaining percentage for non-locked indicators
      const totalIndicators = this.sortedIndicators.length;
      const nonLockedCount = totalIndicators - lockedCount;
      const totalPercentage = 100;
      // Calculate value for non-locked indicators
      const valueForNonLocked = (totalPercentage - lockedSum) / nonLockedCount;

      // Assign values to indicators
      this.sortedIndicators.forEach(indicator => {
        if (!indicator.locked) {
          indicator.w = valueForNonLocked // Adjust precision if needed
        }
        // You may emit an event here to notify parent component if needed
      });
    },
    removeIndicator(indicator) {
      indicator.use = 0;
      // If needed, emit an event or update the parent component
      this.$emit('update-indicator', indicator);
    },
    toggleLock(indicator) {
      indicator.locked = !indicator.locked;
      
      // Sort filteredIndicators only when toggleLock is called
      this.sortIndicators();
    },
    // Method to sort indicators based on lock state and indicator.w value
    sortIndicators() {
      // Separate locked and unlocked indicators again from filteredIndicators
      const lockedIndicators = this.filteredIndicators.filter(indicator => indicator.locked);
      const unlockedIndicators = this.filteredIndicators.filter(indicator => !indicator.locked);

      // Sort by indicator.w value
      lockedIndicators.sort((a, b) => b.w - a.w);
      // unlockedIndicators.sort((a, b) => b.w - a.w);

      // Update sortedIndicators with sorted array
      this.sortedIndicators = [...lockedIndicators, ...unlockedIndicators];
    },
    updateFilteredIndicators() {
      this.filteredIndicators = this.listIndicators.filter(indicator => indicator.use === 1);
      this.sortIndicators(); // Initial sort when filteredIndicators are updated
    }
  },
  watch: {
    // Watch for changes in listIndicators and update filteredIndicators accordingly
    listIndicators: {
      handler() {
        this.updateFilteredIndicators();
      },
      deep: true // Ensure deep watch for changes in array elements
    }
  },
  created() {
    // Initialize filteredIndicators and sortedIndicators when component is created
    this.updateFilteredIndicators();
  },
};
</script>

  
